<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
     
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mx-auto mt-0 ml-0 pa-5">
           <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="3">
              <v-autocomplete
                v-model="dados.banco"
                :items="listaBancos"
                ref="banco"
                name="banco"
                label="Banco"
                item-value="banco"
                item-text="nome_banco"
                return-object
                filled
                clearable
                :name="`banco_${Math.random()}`"
                hide-details
                @change="selBanco"
                @keyup.enter="mudaCampo('agencia')"
              ></v-autocomplete>
            </v-col>
            
             <v-col cols="12" xl="3" lg="2" md="2" sm="3" xs="12">
              <v-text-field
                v-model="dados.agencia"
                ref="agencia"
                name="agencia"
                label="Código Agência"
                value
                filled
                clearable
                :name="`agcricao_${Math.random()}`"
                hide-details
                @keyup.enter="mudaCampo('nome_agencia')"
              ></v-text-field>
            </v-col>
             <v-col cols="12" xl="3" lg="4" md="2" sm="3" xs="12">
              <v-text-field
                v-model="dados.nome_agencia"
                ref="nome_agencia"
                name="nome_agencia"
                label="Nome Agência"
                value
                filled
                clearable
                :name="`noacricao_${Math.random()}`"
                hide-details
                @keyup.enter="mudaCampo('conta')"
              ></v-text-field>
            </v-col>
               <v-col cols="12" xl="3" lg="4" md="2" sm="3" xs="12">
              <v-text-field
                v-model="dados.conta"
                ref="conta"
                name="conta"
                label="Conta"
                value
                filled
                clearable
                :name="`contacricao_${Math.random()}`"
                hide-details
                @keyup.enter="mudaCampo('titular')"
               ></v-text-field>
            </v-col>
             <v-col cols="12" xl="8" lg="6" md="6" sm="8" xs="3">
              <v-text-field
                v-model="dados.titular"
                ref="titular"
                name="titular"
                label="Titular"
                value
                filled
                clearable
                :name="`noacricao_${Math.random()}`"
                hide-details
                @keyup.enter="mudaCampo('status')"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
           
           
             <!--<v-col cols="12" xl="12" lg="9 " md="8" sm="12" xs="12">
              <v-switch
                dense
                flat
                inset
                hide-details=""
                v-model="grupo.chk_nao_res_opera"
                :label="`Não participa do Resultado Operacional`"
                
              ></v-switch>
            </v-col>-->
            
          </v-row>
            <v-row class="mx-auto mt-0 ml-0 pa-5">
             <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                @click="salvar()"
                color="success"
              >
                  <v-icon left>
                 mdi-content-save-outline
                </v-icon>
                 Salvar
              </v-btn>
            </v-col>
             <v-col v-show="editar" cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
               <v-btn
                small
                @click="cancelEdit()"
                color="warning"
              >
                  
                 Cancelar
              </v-btn>
            </v-col>
          </v-row>
          
      
    </v-card>
      <v-card class="mt-5">
     <v-row no-gutters>
      <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">          
    
      <v-data-table
        :headers="headers"
        :items="listaContas"
        :loading="false"
        :search="search"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
        <template v-slot:item.chk_ativo="{ item }">
        <v-switch
                dense
                flat
                inset
                hide-details=""
                v-model="item.chk_ativo"
                 @change="mudarStatus(item.id_conta,item.chk_ativo)"                
              ></v-switch>
          </template>

       <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <!--<v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>-->
            </template>
       
      </v-data-table>  
   
    
     </v-col>
    </v-row>
    </v-card>
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
  
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    this.buscaContas();
    this.buscaBancos();
    this.mudaCampo("banco");
  },
  data: () => ({
    listaBancos:[],
    mostraMsg:false,
    textSnack:"",
    corMsg:"",
    search: "",
    identificacao:"",
    dados:{
      banco:"",
      nome_banco:"",
      agencia:"",
      nome_agencia:"",
      conta:"",
      titular:"",
      chk_ativo:""
    },
    
    items: [],
    headers: [],
    contas:"",
    itemsBread: [
      {
        text: "Cadastros",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Contas Bancárias",
        disabled: false,
        href: "/dashboard/contas",
      },
    ],
    dialog: false,
    grupos:[],
    editar:false,
    listaStatus:[],
       headers: [
    
      {
        text: "Banco",
        value: "banco.nome_banco",
      },
      {
        text: "Conta",
        value: "conta",
      },
     {
        text: "Ativo",
        value: "chk_ativo",
      },
      { text: "Ações", value: "actions", sortable: false },
    ],
    selDel:"",
    selEdit:"",
    dialogDelete:false,
    listaContas:[]
  }),
  methods:{

    selBanco(val){
        this.dados.nome_banco = val.nome_banco;
    },
    getStatus(val){
      console.log(val)
    },
    mudarStatus(id,status){

        axios
        .post(
          `/fin/conta/status`,
          { id: id,chk_status:status, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
         
        
          if(status){
            this.textSnack = "Ativado com sucesso !";
            this.corMsg = "success";
          }else{
            this.textSnack = "Desativado com sucesso!";
            this.corMsg = "error";
          }
          
        
          this.mostraMsg = true;
          
          //this.grupos = response.data;
        });
    },
     editItem(item){
        this.dados = item;
        this.editar = true;
     },
      cancelEdit(){
       this.editar = false;
       this.limpaCampos()
       

     },
     deleteItemConfirm() {
    //  this.listaLancamentos.splice(this.editedIndex, 1);
      this.dialogDelete = false;
      axios
        .post(
          `/fin/grupo/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          
          this.textSnack = "Exclusão feito com sucesso!";
        
          this.mostraMsg = true;
          this.corMsg = "success";
          //this.grupos = response.data;
        });
    },
    limpaCampos(){
      this.dados.banco =""
      this.dados.agencia =""
      this.dados.nome_agencia = ""
      this.dados.conta = ""
      this.dados.titular = null
     
    
    },
    deleteItem(item) {
      
      this.selDel = item.id;
      this.dialogDelete = true;
    },
     mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
     },
     validacao() {

      if (this.dados.banco == "") {
        this.textSnack = "Informe o Banco!";
        this.mudaCampo("banco");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
      if (this.dados.agencia == "") {
        this.textSnack = "Informe o código da Agência!";
        this.mudaCampo("agencia");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       if (this.dados.nome_agencia == "") {
        this.textSnack = "Informe o nome da Agência!";
        this.mudaCampo("nome_agencia");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
        if (this.dados.conta == "") {
        this.textSnack = "Informe o número da conta!";
        this.mudaCampo("conta");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
        if (this.dados.titular == "") {
        this.textSnack = "Informe o titular da conta!";
        this.mudaCampo("titular");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
       
      return true
     },
   
    buscaContas() {
      axios
        .post(
          `/fin/conta/lista_contas`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
    buscaBancos() {
      axios
        .get(
          `/fin/listaBancos`)
        .then((response) => {
          this.listaBancos = response.data;
        });
    },
    salvar(){

        if (!this.validacao()) {
          
          return false;
        }
        axios
        .post(
          `/fin/conta`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: this.dados,
          }
          
        )
        .then((response) => {
        
        
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";
         
          this.mudaCampo("banco");
          this.limpaCampos(); 
          this.buscaContas();       
         
      
        })
        .catch((e) => {
          this.loading = false;
          
          if (!Array.isArray(e.response.data)) {
          
            let itens = [];
            for (var prop in e.response.data) {
              itens.push(e.response.data[prop][0]);
            }
         

            this.textSnack = itens.join("");
            this.mostraMsg = true;
            this.corMsg = "error";
          } else {
         
            this.textSnack = e.response.data;

            this.mostraMsg = true;
            this.corMsg = "error";
          }
        });


    }
  }
};
</script>
